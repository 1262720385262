<template>
  <b-card-actions
      ref="first-card"
      class="list-card"
      @refresh="refreshStop('first-card')"
  >
    <b-row class="mt-50 mb-5">
      <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
        <b-col cols="12">
          <h4 class="text-white d-inline-block card-title">Salon ve Masa Tanımlama</h4>
        </b-col>
      </div>
    </b-row>
    <b-row class="justify-content-end d-flex">
      <b-button
          v-b-modal.add&edit-area
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
      >
        <font-awesome-icon icon="plus" class="mr-50"/>
        Salon Yönetimi
      </b-button>
      <b-button
          v-b-modal.add-table
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-success"
      >
        <font-awesome-icon icon="plus" class="mr-50"/>
        Yeni Masa
      </b-button>
      <b-button
          v-b-modal.add-multiple-table
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-info"
      >
        <font-awesome-icon icon="check-double" class="mr-50"/>
        Çoklu Masa Ekleme
      </b-button>
    </b-row>
    <hr>
    <b-tabs :key="'tabs-'+count" v-model="activeTab">
      <b-tab v-for="item in dashData" :key="item.salonID" :title="item.salonAdi">
        <b-row>
          <b-col v-for="masa in item.masaListesi" :key="masa.masaID" class="masa">
            <h5>{{ masa.masaAdi }}</h5>
            <b-row>
              <b-button
                  v-b-modal.update-table
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon edit-btn"
                  @click="updateTableData = {...masa}"
              >
                <font-awesome-icon icon="pen"/>
              </b-button>
            </b-row>
          </b-col>
          <b-col v-b-modal.add-table class="masa pull-up cursor-pointer">
            <font-awesome-icon icon="plus" class="fa-2xl"/>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <b-modal
        id="add&edit-area"
        :key="count"
        centered
        title="Salon Tanımlama"
        ok-title="Kapat"
        ok-variant="secondary"
        ok-only
        @hide="dashBoardData()"
    >
      <b-row class="add-area-grid">
        <b-form-input
            id="basicInput"
            v-model="addAreaName"
            placeholder="Yeni Salon"
            autocomplete="off"
            class="grid-1-6"
            :style="{borderColor : !updateAreaID ? '#d8d6de':'orange'}"
        />
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :variant="!updateAreaID ? 'flat-success':'flat-primary'"
            :disabled="!addAreaName"
            @click="!updateAreaID ? saveSalon() : updateSalon()"
        >
          {{ !updateAreaID ? 'Ekle' : 'Güncelle' }}
        </b-button>
      </b-row>
      <hr>
      <transition-group
          type="transition"
          name="flip-list"
      >
        <b-list-group-item
            v-for="listItem in dashData"
            :key="listItem.salonID"
            tag="li"
            :style="{backgroundColor:updateAreaID === listItem.salonID ? '#FFD6A5':'transparent'}"
        >
          <b-row>
            <b-col cols="12">
              <h5 class="m-0 d-inline-block">
                {{ listItem.salonAdi }}
              </h5>
              <b-badge
                  href="#"
                  variant="danger"
                  class="float-right"
                  @click="removeSalon(listItem.salonID)"
              >
                <font-awesome-icon icon="trash" class="mr-50"/>
                <span>Sil</span>
              </b-badge>
              <b-badge
                  v-if="updateAreaID !== listItem.salonID"
                  href="javascript:void(0)"
                  variant="primary"
                  class="mr-25 ml-2 float-right"
                  @click="addAreaName = listItem.salonAdi,updateAreaID = listItem.salonID"
              >
                <font-awesome-icon icon="pen" class="mr-50"/>
                <span>Düzenle</span>
              </b-badge>
              <b-badge
                  v-if="updateAreaID === listItem.salonID"
                  href="javascript:void(0)"
                  variant="warning"
                  class="mr-25 ml-2 float-right"
                  @click="addAreaName = null,updateAreaID = null"
              >
                <font-awesome-icon icon="pen" class="mr-50"/>
                <span>Vazgeç</span>
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
      </transition-group>
    </b-modal>
    <b-modal
        id="add-table"
        centered
        title="Masa Tanımlama"
        ok-title="Kaydet"
        cancel-title="Vazgeç"
        cancel-variant="warning"
        @ok="saveTable(false)"
    >
      <b-form-group
          label="Masa Adı"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="addTable.name"
            autocomplete="off"
            placeholder="Masa Adı"
            @keyup.enter="saveTable(false)"
        />
      </b-form-group>
    </b-modal>
    <b-modal
        id="update-table"
        centered
        title="Masa Düzenle"
        hide-footer
        @hide="updateTableData = []"
    >
      <b-form-group
          label="Masa Adı"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="updateTableData.masaAdi"
            autocomplete="off"
            placeholder="Masa Adı"
            @keyup.enter="updateTable()"
        />
      </b-form-group>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mr-50"
              @click="masaSil(updateTableData.masaID)"
          >
            Masayı Sil
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="!updateTableData.masaAdi"
              @click="updateTable()"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="add-multiple-table"
        centered
        title="Çoklu Masa Tanımlama"
        ok-title="Kaydet"
        cancel-title="Vazgeç"
        cancel-variant="warning"
        @ok="saveTable(true)"
    >
      <b-form-group
          label="Masa Adı"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="addMultipleTable.name"
            autocomplete="off"
            placeholder="Masa Adı"
        />
      </b-form-group>
      <b-form-group
          label="Adet"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="addMultipleTable.count"
            autocomplete="off"
            placeholder="Masa Adı"
        />
      </b-form-group>
    </b-modal>
  </b-card-actions>
</template>

<script>
import {
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'

export default {
  components: {
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      updateTableData: [],
      activeTab: null,
      count: 0,
      isOnline: false,
      addTable: {
        name: 'Masa',
      },
      addMultipleTable: {
        name: 'Masa',
        count: 10,
      },
      addAreaName: null,
      updateAreaID: null,
    }
  },
  computed: {
    salonID() {
      return Object.values(this.dashData)[this.activeTab].salonID
    },
    dashData: () => store.getters.GET_DASH_DATA,
  },
  created() {
    if (!store.getters.GET_DASH_DATA) {
      this.dashBoardData()
    }
  },
  methods: {
    masaSil(masaID) {
      const fd = this.postSchema()
      fd.set('serviceName', 'restoran')
      fd.append('methodName', 'masaSil')
      fd.append('masaID', masaID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.updateTableData = []
              this.$bvModal.hide('update-table')
              this.dashBoardData()
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'dashBoard')
      return fd
    },
    saveSalon() {
      const fd = this.postSchema()
      fd.set('serviceName', 'restoran')
      fd.append('methodName', 'salonKayit')
      fd.append('salonAdi', this.addAreaName)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.addAreaName = null
              this.dashBoardData()
            }
          })
    },
    updateSalon() {
      const fd = this.postSchema()
      fd.set('serviceName', 'restoran')
      fd.append('methodName', 'salonDuzenle')
      fd.append('salonID', this.updateAreaID)
      fd.append('salonAdi', this.addAreaName)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.updateAreaID = null
              this.addAreaName = null
              this.dashBoardData()
            }
          })
    },
    removeSalon(salonID) {
      const fd = this.postSchema()
      fd.set('serviceName', 'restoran')
      fd.append('methodName', 'salonSil')
      fd.append('salonID', salonID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.dashBoardData()
            }
          })
    },
    updateTable() {
      if (this.updateTableData.masaAdi) {
        const fd = this.postSchema()
        fd.set('serviceName', 'restoran')
        fd.append('methodName', 'masaDuzenle')
        fd.append('masaID', this.updateTableData.masaID)
        fd.append('updateList[salonID]', this.updateTableData.salonID)
        fd.append('updateList[masaAdi]', this.updateTableData.masaAdi)
        axios.post('', fd)
            .then(response => {
              if (response.result.status === 200) {
                this.$bvModal.hide('update-table')
                this.dashBoardData()
                this.updateTableData = []
              }
            })
      }
    },
    saveTable(multiple = false) {
      if (multiple) {
        const fd = this.postSchema()
        fd.set('serviceName', 'restoran')
        fd.append('methodName', 'cokluMasaKayit')
        fd.append('salonID', this.salonID)
        fd.append('masaAdi', this.addMultipleTable.name)
        fd.append('adet', this.addMultipleTable.count)
        axios.post('', fd)
            .then(response => {
              if (response.result.status === 200) {
                this.dashBoardData()
                this.addMultipleTable = {
                  name: 'Masa',
                  count: 10,
                }
              }
            })
      } else {
        const fd = this.postSchema()
        fd.set('serviceName', 'restoran')
        fd.append('methodName', 'masaKayit')
        fd.append('salonID', this.salonID)
        fd.append('masaAdi', this.addTable.name)
        axios.post('', fd)
            .finally(() => this.dashBoardData())
      }
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    dashBoardData() {
      const fd = this.postSchema()
      fd.set('serviceName', 'dashBoard')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_DASH_DATA',
                action: 1,
                list: response.dashData,
              })
            }
          })
    },
  },
}
</script>
<style>

.masa {
  margin: 10px;
  background-color: #f0f0f0;
  border: 1px solid #999;
  justify-content: center;
  display: grid;
  align-items: center;
  min-width: 200px !important;
  min-height: 150px !important;
  max-width: 200px !important;
  max-height: 150px !important;
}

.masa .edit-btn {
  position: absolute !important;
  top: 0;
  right: 0;
}

.add-area-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  gap: 10px;
  padding: 10px;
}

.grid-1-6 {
  grid-column: 1 / 6;
}

.list-moved-icon {
  display: grid;
  place-items: center;
  justify-content: end;
}

</style>
